export type TypeGuard<T, V extends T> = (val: T) => val is V;

export function isNull<T>(input: T | null): input is null {
  return input === null;
}

export function isUndefined<T>(input: T | undefined): input is undefined {
  return input === undefined;
}

export function isMissing<T>(input: T | null | undefined): input is null | undefined {
  return input === null || input === undefined;
}

export function notNull<T>(input: T | null): input is T {
  return input !== null;
}

export function notUndefined<T>(input: T | undefined): input is T {
  return input !== undefined;
}

export function notMissing<T>(input: T | null | undefined): input is T {
  return input !== null && input !== undefined;
}

export function isString<T>(input: T | string): input is string {
  return typeof input === 'string';
}

export function isNumber<T>(input: T | number): input is number {
  return typeof input === 'number';
}

/**
 * This is useful to ensure that a switch case block is exhaustive
 * while still providing a default case
 * you can throw the result of the check if wanted.
 * example usage:
 * throw checkNever(myVar, 'Unexpected value for var: ' + myVar);
 */
export function checkNever(x: never, msg?: string): Error {
  if (isMissing(msg)) {
    msg = 'Unexpected value: ' + x;
  }
  return new Error(msg);
}
