import * as React from 'react';
import styles from './verify.module.scss';
import Head from '../../components/Head';
import { parse } from 'qs';
import logoFull from '../../../assets/images/logo_full_purple.png';
import { Alert, AlertType } from '../../components/Alert';
import { isMissing } from '../../lib/typeGuards';
import { PageRendererProps } from 'gatsby';
import { apiClient, isResponseError, extractMessageAndPath } from '../../api/APIClient';

const VerifyReviewPage: React.FunctionComponent<PageRendererProps> = ({ location }) => {
  const query = parse(location.search.substr(1));
  const token = query.token as string | undefined;

  const [completed, setCompleted] = React.useState(false);
  const [error, setError] = React.useState<string | null>(null);

  const pageContainer = (content: any) => (
    <div className={styles.VerifyReviewPage}>
      <Head title='Opinous | Verify Review' />
      <div className={styles.Content}>
        <h1>Verify Review </h1>
        {content}
      </div>
      <div className={styles.Footer}>
        <div>verified by</div>
        <a href='https://opinous.com' target='_blank'>
          <img src={logoFull} />
        </a>
      </div>
    </div>
  );

  if (isMissing(token)) {
    return pageContainer(null);
  }

  if (token.length !== 36) {
    return pageContainer(
      <section>
        <Alert type={AlertType.Error}>The verification token provided doesn't look valid</Alert>
      </section>,
    );
  }

  React.useEffect(() => {
    apiClient
      .verifyReview(token)
      .then(() => setCompleted(true))
      .catch((e) => {
        if (isResponseError(e)) {
          const [message] = extractMessageAndPath(e);
          setError(message);
        } else {
          setError('Could not verify review');
        }
      });
  }, []);

  if (completed) {
    return pageContainer(
      <div className={styles.Completed}>
        <i className='far fa-check' />
        <p>All verified! You may now close this window</p>
      </div>,
    );
  }

  if (error) {
    return pageContainer(
      <div className={styles.Error}>
        <i className='far fa-times' />
        <p>{error}</p>
      </div>,
    );
  }

  return pageContainer(
    <div className={styles.Loading}>
      <i className='far fa-sync-alt fa-spin' />
      <p>We're verifying your review. Just a sec!</p>
    </div>,
  );
};

export default VerifyReviewPage;
