import * as React from 'react';
import classNames from 'classnames';
import styles from './Alert.module.scss';

export enum AlertType {
  Error = 'Error',
  Success = 'Success',
  Info = 'Info',
  Loading = 'Loading',
}

interface OwnProps {
  type: AlertType;
  children: any;
}

export const Alert = (props: OwnProps) => {
  let icon: string;

  switch (props.type) {
    case AlertType.Success:
      icon = 'fas fa-check-circle';
      break;
    case AlertType.Error:
      icon = 'fas fa-exclamation-circle';
      break;
    case AlertType.Loading:
      icon = 'far fa-sync-alt fa-spin';
      break;
    default:
      icon = 'fas fa-info-circle';
  }

  return (
    <div className={classNames(styles.Alert, styles[props.type])}>
      <i className={icon} />
      <div>{props.children}</div>
    </div>
  );
};
