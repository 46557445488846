import axios, { AxiosResponse, AxiosError } from 'axios';

enum HTTPMethod {
  GET = 'get',
  POST = 'post',
  PATCH = 'patch',
}

interface AxiosResponseError extends AxiosError {
  response: AxiosResponse;
}

export function isResponseError(e: any): e is AxiosResponseError {
  return 'config' in e && 'response' in e;
}

export function extractMessageAndPath(e: AxiosResponseError): [string, string | undefined] {
  return [
    e.response && 'errorMessage' in e.response.data
      ? e.response.data.errorMessage
      : 'An unexpected error occurred',
    e.response && 'path' in e.response.data ? e.response.data.path : undefined,
  ];
}

export interface SubmitReviewResponse {
  id: string;
}

export enum ReviewState {
  INVITED = 'INVITED',
  SUBMITTED = 'SUBMITTED',
  VERIFIED = 'VERFIED',
  REJECTED = 'REJECTED',
  RESOLVED = 'RESOLVED',
}

export enum MetadataFieldType {
  DROPDOWN = 'DROPDOWN',
  TEXT = 'TEXT',
  RATING = 'RATING',
}

export interface MetadataField {
  id: string;
  name: string;
  priority: number;
  field_title: string;
  field_sub_title: string | null;
  field_placeholder: string | null;
  field_type: MetadataFieldType;
  field_values: string[] | null;
  is_required: boolean;
}

export interface ReviewFormMetadata {
  organization_name: string;
  location_name: string;
  reviewer_name: string | null;
  review_state: ReviewState | null;
  metadata_fields: MetadataField[];
}

export class OpinousAPI {
  public constructor(public baseUrl: string) {}

  public async completeReview(
    locationId: string,
    args: {
      token: string;
      full_name: string;
      review_body: string;
      overall_rating: number;
      metadata: {};
    },
  ): Promise<SubmitReviewResponse> {
    const response = await this.sendRequest<SubmitReviewResponse>(
      HTTPMethod.POST,
      `/v1/locations/${locationId}/complete-review`,
      args,
    );

    return response.data;
  }

  public async submitReview(
    locationId: string,
    args: {
      full_name: string;
      email_address: string;
      review_body: string;
      overall_rating: number;
      metadata: {};
    },
  ): Promise<SubmitReviewResponse> {
    const response = await this.sendRequest<SubmitReviewResponse>(
      HTTPMethod.POST,
      `/v1/locations/${locationId}/submit-review`,
      args,
    );

    return response.data;
  }

  public async verifyReview(verification_token: string): Promise<{ is_success: boolean }> {
    const response = await this.sendRequest<{ is_success: boolean }>(
      HTTPMethod.POST,
      `/v1/reviews/verify`,
      {
        verification_token,
      },
    );

    return response.data;
  }

  public async getReviewFormMetadata(
    locationId: string,
    token: string = '',
  ): Promise<ReviewFormMetadata> {
    if (token.length > 0) {
      token = `?token=${token}`;
    }
    const response = await this.sendRequest<ReviewFormMetadata>(
      HTTPMethod.GET,
      `/v1/locations/${locationId}/review-meta${token}`,
    );

    return response.data;
  }

  private async sendRequest<Resp extends Object>(
    method: HTTPMethod,
    endpoint: string,
    data?: Object,
  ): Promise<AxiosResponse<Resp>> {
    return await axios.request({
      method,
      url: `${this.baseUrl}${endpoint}`,
      data,
    });
  }
}

export const apiClient = new OpinousAPI(process.env.GATSBY_API_URL!);
